import React, {useState} from "react"
import {graphql} from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";
import imgPage from "../images/headers/Logos_Ale_Service_Center_Blue.svg"
import Header from "../components/header";
import Img from "gatsby-image"
import style from "./service.module.scss"

import mantenimiento from "../images/service/mantenimiento-icono.png"
import pintura1 from "../images/service/pintura-icono.png"
import pintura2 from "../images/service/pintura2-icono.png"
import avionica from "../images/service/avionica-icono.png"
import control from "../images/service/control.png"

import beechcraft from "../images/service/logo_Textron.png"
import raisbeck from "../images/service/Logo-Raisbeck.png"
import Blackhawk from "../images/service/Logo-Blackhawk.png"
import honey from "../images/service/honeywell-logo.png"
import pratt from "../images/service/pratt-logo.png"
import leonardo from "../images/service/leonardo-logo.png"
import rock from "../images/service/rockwell-logo.png"


import ReactWOW from "react-wow";
import Form from "../components/form.en";

export default ({data}) => {

    const [slide, setSlide] = useState(1);

    function goToSlide(x) {
        setSlide(x)
    }

    const extraInfo =
        [
            {name: 'Sales', mail: 'ventas@alesc.mx', phone: '722 279 1650'},
            {name: 'Customer Service', mail: 'cpm@alesc.mx', phone: ''}
        ];

    return (
        <Layout extraInfo={extraInfo}>
            <SEO title="Service Center"/>
            <Header LogoPage={imgPage}/>

            <div className={style.serviceCover}>
                <Img fluid={data.portada.childImageSharp.fluid}/>
            </div>

            <Form options={['Ale Service Center: Workshop']}/>

            <ReactWOW animation='fadeIn'>
                <div className={style.serviceInfo}>
                    <h1>Service Center</h1>
                    <p>Ale Service Center specializes in providing excellent service for Hawker and Beechcraft
                        aircrafts, Leonardo Helicopters and Textron Aviation.</p>
                    <p>We are the only Service Center in México authorized by Textron Aviation for Hawker, Beechcraft
                        and Cessna aircrafts; In addition to having the exclusive representation for Leonardo
                        helicopters in the models Agusta 109, 119, and AW 139.</p>
                </div>
            </ReactWOW>


            <div className={style.serviceOur}>
                <h2>OUR SERVICES</h2>
                <div className={style.services}>

                    <div className={style.service}>
                        <div className={style.img}>
                            <img src={mantenimiento} alt="Mantenimiento"/>
                        </div>
                        <p><b>MAINTENANCE</b></p>
                        <p>We extend our determined capabilities to exceed your expectations.</p>
                    </div>

                    <div className={style.service}>
                        <div className={style.img}>
                            <img src={pintura1} alt="Pintura"/>
                        </div>
                        <div className={style.img}>
                            <img src={pintura2} alt="Pintura"/>
                        </div>
                        <p><b>PAINTING AND INTERIORS</b></p>
                        <p>We combine top-notch facilities with the best workmanship to provide you with a high
                            technical quality service.</p>
                    </div>

                    <div className={style.service}>
                        <div className={style.img}>
                            <img src={avionica} alt="Aviónica"/>
                        </div>
                        <p><b>AVIONICS</b></p>
                        <p><span>ALE Service Center</span> leads the industry in facilities experience within executive
                            aviation in México. Avionics updates increase the safety, performance and value of your
                            aircraft.</p>
                    </div>

                    <div className={style.service}>
                        <div className={style.img}>
                            <img src={control} alt="Mantenimiento"/>
                        </div>
                        <p><b>MAINTENANCE CONTROL</b></p>
                        <p>With the widest experience, we manage maintenance control on more than 120 aircrafts, under
                            contract registered with the DGAC.</p>
                    </div>

                    <div className={style.more}>
                        <a href="http://aleservicecenter.com/" target="_blank" rel="noopener noreferrer">Learn More</a>
                    </div>

                </div>
            </div>

            <div className={style.serviceBrands}>
                <h2>AUTHORIZED SERVICE CENTER</h2>
                <p><b>WE ARE ALSO THE MAINTENANCE CENTER OF OUR FLEET</b></p>
                <div className={style.items}>
                <div className={style.item}>
                        <a href="https://beechcraft.txtav.com/" target='_blank' rel="noopener noreferrer">
                            <img src={beechcraft} alt="Beechcraft"/>
                        </a>
                        <p><b>Beechcraft</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://raisbeck.com/" target='_blank' rel="noopener noreferrer">
                            <img src={raisbeck} alt="Raisbeck"/>
                        </a>
                        <p><b>Raisbeck</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://www.blackhawk.aero/" target='_blank' rel="noopener noreferrer">
                            <img src={Blackhawk} alt="Blackhawk"/>
                        </a>
                        <p><b>Blackhawk</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://aerospace.honeywell.com/en" target='_blank' rel="noopener noreferrer">
                            <img src={honey} alt="Honeywell"/>
                        </a>
                        <p><b>Honeywell</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://www.pw.utc.com/" target='_blank' rel="noopener noreferrer">
                            <img src={pratt} alt="Pratt & Whitney"/></a>
                        <p><b>Pratt & Whitney</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://www.leonardocompany.com/en/home/" target='_blank' rel="noopener noreferrer">
                            <img src={leonardo} alt="Leonardo"/></a>
                        <p><b>Leonardo</b></p>
                    </div>
                    <div className={style.item}>
                        <a href="https://www.rockwellcollins.com/" target='_blank' rel="noopener noreferrer">
                            <img src={rock} alt="Textron"/></a>
                        <p><b>Rockwell Collins</b></p>
                    </div>

                </div>
            </div>


            <div className={style.serviceTitle}>
                <p>MAINTENANCE CAPABILITIES</p>
                <span> </span>
            </div>

            <div className={style.serviceCapacities}>
                <div className={style.bullets}>
                    <button className={slide === 1 ? `${style.active}` : ''} onClick={(e) => goToSlide(1)}>
                    </button>
                    <button className={slide === 2 ? `${style.active}` : ''} onClick={(e) => goToSlide(2)}>
                    </button>
                    <button className={slide === 3 ? `${style.active}` : ''} onClick={(e) => goToSlide(3)}>
                    </button>
                </div>
                <div className={slide === 1 ? `${style.active}` : ''}>
                    <p><b>Citation M2</b></p>
                    <p><b>Citation CJ2, CJ3, CJ4</b></p>
                    <p><b>Citation Excel</b></p>
                    <p><b>Citation XL, XLS, XSL+</b></p>
                    <p><b>Citation Sovereign</b></p>
                    <p><b>Citation Latitude</b></p>
                </div>
                <div className={slide === 2 ? `${style.active}` : ''}>
                    <p><b>King Air 90 Series</b></p>
                    <p><b>King Air 200 Series</b></p>
                    <p><b>King Air 350 Series</b></p>
                    <p><b>Challenger 604, 605, 650</b></p>
                    <p><b>Honda HA-420</b></p>
                    <p><b>Leonardo Helicopters</b></p>
                </div>
                <div className={slide === 3 ? `${style.active}` : ''}>
                    <p><b>Hawker 750/800/900</b></p>
                    <p><b>Hawker 4000</b></p>
                    <p><b>Beechjet / 400XP</b></p>
                    <p><b>Premier 1/1A</b></p>
                    <p><b>Beech Piston</b></p>
                    <p><b>Learjet 40/45/60/70/75</b></p>
                </div>
            </div>


            <div className={style.serviceImages}>
                <Img fluid={data.foto1.childImageSharp.fluid}/>
                <div className={style.title}>
                    <p>PAINTING AND INTERIORS</p>
                    <span> </span>
                </div>

                <div className={style.infoBlock}>
                    <Img className={style.img} fluid={data.foto2.childImageSharp.fluid}/>
                    <div className={style.info}>
                        <div className={style.ul}>
                            <p>We combine top-notch facilities with the best workmanship to provide you with quality
                                service, being the best choice to renew the image of your aircraft. Our hangar complies
                                with the strictest standards of quality and environmental protection of the FAA and EASA
                                (European Aviation Safety Agency). *WORKSHOP CERTIFIED BY: FAA: UQXZ990X and DGAC:
                                253.</p>
                            <p>We renew your aircraft! We have a wide range of models, shades and designs in materials
                                such as leathers, carpets, ultra-leather, fabrics, platinum plated, blinds, webbing,
                                among others. The materials we use are certified under FAA’s FAR Standard 25.</p>
                        </div>
                    </div>
                </div>

                {/*<div className={style.title}>
                    <p>Lorem Ipsum</p>
                    <span> </span>
                </div>*/}

                {/*<div className={style.infoBlock}>
                    <div className={style.info}>
                        <div className={style.ul}>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ab accusamus at aut eligendi ex
                                fugit illo labore magnam magni minima necessitatibus nostrum odit, quasi quos ut
                                veritatis
                                voluptates. In, maxime?</p>
                        </div>
                    </div>
                    <img src={Foto3} alt="Lorem"/>
                </div>*/}

                <div className={style.title}>
                    <p>Modifications / Upgrades</p>
                    <span> </span>
                </div>

                <div className={style.infoBlock}>
                    <div className={style.info}>
                        <div className={style.ul}>
                            <p><b>AVIONICS</b></p>
                            <p>We are the Rockwell Collins Authorized Service Center for testing and facilities; and
                                Honeywell’s at line level.</p>
                        </div>
                    </div>
                    <Img className={style.img} fluid={data.foto3.childImageSharp.fluid}/>

                </div>
            </div>

        </Layout>
    )

}


export const query = graphql`
    query ImagesCenterEn {
    portada: file(relativePath: {eq: "service/portada.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto1: file(relativePath: {eq: "service/foto1.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2: file(relativePath: {eq: "service/foto2.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto3: file(relativePath: {eq: "service/foto3.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}`;





